export const ApiCode = Object.freeze({
  SUCCESS: 1,
  TOKEN_EXPIRED: 8,
})
const maxOrderNoPath = '/max/order-no/'
export const ApiBaseRoutes = Object.freeze({
  creditClass: 'uni/credit_classes',
  creditClassTeacher: 'uni/credit_class/teachers',
  creditClassStudent: 'uni/credit_class/students',
  programme: 'uni/programmes',
  programmeSubject: 'uni/programme/subjects',
  trainingSystem: 'uni/trainingSystems',
  course: 'uni/courses',
  courseSemester: 'uni/course/semesters',
  department: 'uni/departments',
  major: 'uni/majors',
  subject: 'uni/subjects',
  days: 'uni/days/get_all_day',
  turns: 'uni/turns/get_all_turn',
  rooms: 'uni/rooms',
  creditClassRoom: 'uni/credit_class_rooms',
  teachers: 'uni/teachers',
  class: 'uni/classes',
  teacher: 'uni/teachers',
  student: 'uni/students',
  studentClass: 'uni/studentClasses',
  report: 'uni/report',
  building: 'uni/buildings',
  title: 'uni/titles',
  diploma: 'uni/diplomas',
  diplomaStudent: 'uni/diplomas/students',
  programmeSubjectGroup: 'uni/programme/subjects/groups',
  setOfCriteriaObjects: 'setOfCriteriaObjects',
})
export const ApiExtRoutes = Object.freeze({
  creditClassMaxOrderNo: `${ApiBaseRoutes.creditClass}${maxOrderNoPath}`,
  creditClassTeacherMaxOrderNo: `${ApiBaseRoutes.creditClassTeacher}${maxOrderNoPath}`,
  creditClassStudentMaxOrderNo: `${ApiBaseRoutes.creditClassStudent}${maxOrderNoPath}`,
  courseSemesterMaxOrderNo: `${ApiBaseRoutes.courseSemester}${maxOrderNoPath}`,
  studentClassMaxOrderNo: `${ApiBaseRoutes.studentClass}${maxOrderNoPath}`,
  creditClassCopy: `${ApiBaseRoutes.creditClass}/copy-class/`,
  planCreditClass: `${ApiBaseRoutes.creditClass}/plan_by_programme/`,
  reportPointByCreditClass: `${ApiBaseRoutes.report}/credit-class`,
  creditClassRoomByCreditClass: `${ApiBaseRoutes.creditClassRoom}/credit_class`,
  allCourseSemester: `${ApiBaseRoutes.courseSemester}/all`,

  ...ApiBaseRoutes,
})
export default { ApiCode, ApiBaseRoutes, ApiExtRoutes }
